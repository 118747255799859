<template>
  <div>
    <b-modal
      ref="refModalDatePickerIdStrDate"
      centered
      title="วันที่ออกบัตร ปชช."
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="getInputIdStrDate"
        class="mt-6"
        locale="th"
        @input="refModalDatePickerIdStrDate.hide()"
      />
    </b-modal>
    <b-modal
      ref="refModalDatePickerIdEndDate"
      centered
      title="วันที่หมดอายุบัตร ปชช."
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="getInputIdEndDate"
        class="mt-6"
        locale="th"
        @input="refModalDatePickerIdEndDate.hide()"
      />
    </b-modal>
    <b-modal
      ref="refModalDatePickerBthDate"
      centered
      title="วันเกิด"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="getInputBthDate"
        class="mt-6"
        locale="th"
        @input="refModalDatePickerBthDate.hide()"
      />
    </b-modal>
    <b-modal
      ref="refModalDatePickerDrvLicStr"
      centered
      title="วันที่ออกใบขับขี่"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="getInputDrvLicStr"
        class="mt-6"
        locale="th"
        @input="refModalDatePickerDrvLicStr.hide()"
      />
    </b-modal>
    <b-modal
      ref="refModalDatePickerDrvLicEnd"
      centered
      title="วันที่ออกใบขับขี่"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="getInputDrvLicEnd"
        class="mt-6"
        locale="th"
        @input="refModalDatePickerDrvLicEnd.hide()"
      />
    </b-modal>

    <b-row>
      <b-col :cols="12">
        <hr>
        <h3>ข้อมูลเบื้องต้น</h3>
      </b-col>
    </b-row>

    <b-row class="delete-margin">
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label-for="branchCode"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              พอร์ต
            </div>
          </template>
          <b-input-group v-show="!branchCode">
            <b-form-input
              id="branchCode"
              v-model="inputSearchBranch"
              placeholder="ค้นหาพอร์ต"
              trim
              @keydown.enter.native="
                fncSearchBranch(inputSearchBranch)
              "
            />
          </b-input-group>
          <b-input-group v-show="branchCode">
            <b-form-input
              id="branchCode"
              :value="branchName"
              trim
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="delete-margin">
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label-for="chassisNum"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              หมายเลขตัวถัง
            </div>
          </template>
          <b-input-group v-show="!chassisNum">
            <b-form-input
              id="chassisNum"
              v-model="inputSearchChassisNum"
              placeholder="ค้นหาหมายเลขตัวถัง"
              trim
              @keydown.enter.native="
                fncSelectChassisNum(inputSearchChassisNum)
              "
            />
          </b-input-group>
          <b-input-group v-show="chassisNum">
            <b-form-input
              id="chassisNum"
              :value="chassisNum"
              trim
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label-for="regNum"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              หมายเลขทะเบียน
            </div>
          </template>
          <b-form-input
            id="inputRegNum1"
            v-model="getInputRegNum1"
            placeholder="เลขทะเบียน"
            trim
            style="display: inline;width: 49%;"
            disabled
          />
          <b-form-input
            id="inputRegNum2"
            v-model="getInputRegNum2"
            placeholder="เลขทะเบียน"
            trim
            style="display: inline;width: 49%;  margin-left: 5px;"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="เลขที่คำขอ"
          label-for="GenAppNum"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              เลขที่คำขอ
            </div>
          </template>
          <b-form-input
            id="GenAppNum"
            v-model="getInputGenAppNum"
            placeholder="เลขที่คำขอ"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="เลขสัญญา"
          label-for="ConNum"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              เลขสัญญา
            </div>
          </template>
          <b-form-input
            id="ConNum"
            v-model="getInputConNum"
            placeholder="เลขสัญญา"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="สหกรณ์"
          label-for="CooPerateCode"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              สหกรณ์
            </div>
          </template>
          <b-form-input
            id="CooPerateCode"
            v-model="getInputCooPerateCode"
            placeholder="สหกรณ์"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="สถานที่จอดรถ"
          label-for="CarLocation"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              สถานที่จอดรถ
            </div>
          </template>
          <b-form-input
            id="CarLocation"
            v-model="getInputCarLocation"
            placeholder="สถานที่จอดรถ"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="delete-margin">
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label-for="salesCode"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              เซลล์
            </div>
          </template>
          <b-input-group v-show="!salesCode">
            <b-form-input
              id="salesCode"
              v-model="inputSearchSales"
              placeholder="ค้นหาเซลล์"
              trim
              @keydown.enter.native="
                fncSearchSales(inputSearchSales)
              "
            />
          </b-input-group>
          <b-input-group v-show="salesCode">
            <b-form-input
              id="salesCode"
              :value="salesCode"
              trim
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="ชื่อเซลล์"
          label-for="salesName"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              ชื่อเซลล์
            </div>
          </template>
          <b-form-input
            id="salesName"
            v-model="salesName"
            placeholder="ชื่อเซลล์"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="12">
        <hr>
        <h3>ข้อมูลลูกค้า</h3>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="เลขที่บัตรประชาชน"
          label-for="PsnRegIdn"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              เลขที่บัตรประชาชน
            </div>
          </template>
          <b-form-input
            id="PsnRegIdn"
            v-model="getInputPsnRegIdn"
            placeholder="เลขที่บัตรประชาชน"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label-for=""
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              วันที่ออกบัตร ปชช.
            </div>
          </template>
          <b-form-input
            id="InputIdStrDate"
            :value="resolveFormatDate(inputIdStrDate)"
            trim
            readonly
            placeholder="วันที่ออกบัตร ปชช."
            disabled
            @click="fncShowDatepickerIdStrDate()"
          />
        </b-form-group>
      </b-col>

      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label-for=""
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              วันที่หมดอายุบัตร ปชช.
            </div>
          </template>
          <b-form-input
            id="InputIdEndDate"
            :value="resolveFormatDate(inputIdEndDate)"
            trim
            readonly
            placeholder="วันที่หมดอายุบัตร ปชช."
            disabled
            @click="fncShowDatepickerIdEndDate()"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="delete-margin">
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label-for="cusPsnCode"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              คำนำหน้า
            </div>
          </template>
          <b-input-group v-show="!cusPsnCode">
            <b-form-input
              id="cusPsnCode"
              v-model="inputSearchCusPsn"
              placeholder="คำนำหน้า"
              trim
              @keydown.enter.native="
                fncSearchCusPsn(inputSearchCusPsn)
              "
            />
          </b-input-group>
          <b-input-group v-show="cusPsnCode">
            <b-form-input
              id="cusPsnCode"
              :value="cusPsnName"
              trim
              disabled
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="ชื่อ"
          label-for="CusNamTha"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              ชื่อ
            </div>
          </template>
          <b-form-input
            id="CusNamTha"
            v-model="getInputCusNamTha"
            placeholder="ชื่อ"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="นามสกุล"
          label-for="CusSurTha"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              นามสกุล
            </div>
          </template>
          <b-form-input
            id="CusSurTha"
            v-model="getInputCusSurTha"
            placeholder="นามสกุล"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label-for=""
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              วันเกิด
            </div>
          </template>
          <b-form-input
            id="InputBthDate"
            :value="resolveFormatDate(inputBthDate)"
            trim
            readonly
            placeholder="วันเกิด"
            disabled
            @click="fncShowDatepickerBthDate()"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="เลขที่ใบขับขี่"
          label-for="drvLicenseNo"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              เลขที่ใบขับขี่
            </div>
          </template>
          <b-form-input
            id="drvLicenseNo"
            v-model="getInputDrvLicenseNo"
            placeholder="เลขที่ใบขับขี่"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label-for=""
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              วันที่ออกใบขับขี่
            </div>
          </template>
          <b-form-input
            id="InputDrvLicStr"
            :value="resolveFormatDate(inputDrvLicStr)"
            trim
            readonly
            placeholder="วันที่ออกใบขับขี่"
            disabled
            @click="fncShowDatepickerDrvLicStr()"
          />
        </b-form-group>
      </b-col>

      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label-for=""
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              วันที่หมดอายุใบขับขี่
            </div>
          </template>
          <b-form-input
            id="InputDrvLicEnd"
            :value="resolveFormatDate(inputDrvLicEnd)"
            trim
            readonly
            placeholder="วันที่หมดอายุใบขับขี่"
            disabled
            @click="fncShowDatepickerDrvLicEnd()"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="12">
        <hr>
        <h3>ที่อยู่ลูกค้า</h3>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="บ้านเลขที่"
          label-for="adrDtlln1"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              บ้านเลขที่
            </div>
          </template>
          <b-form-input
            id="adrDtlln1"
            v-model="getInputAdrDtlln1"
            placeholder="บ้านเลขที่"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>

      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="ตำบล/แขวง"
          label-for="adrDisTrict"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              ตำบล/แขวง
            </div>
          </template>
          <b-form-input
            id="adrDisTrict"
            v-model="getInputAdrDisTrict"
            placeholder="ตำบล/แขวง"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="อำเภอ/เขต"
          label-for="adrAmPhur"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              อำเภอ/เขต
            </div>
          </template>
          <b-form-input
            id="adrAmPhur"
            v-model="getInputAdrAmPhur"
            placeholder="อำเภอ/เขต"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>

      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="จังหวัด"
          label-for="adrProVince"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              จังหวัด
            </div>
          </template>
          <b-form-input
            id="adrProVince"
            v-model="getInputAdrProVince"
            placeholder="จังหวัด"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="รหัสไปรษณีย์"
          label-for="adrZipCod"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              รหัสไปรษณีย์
            </div>
          </template>
          <b-form-input
            id="adrZipCod"
            v-model="getInputAdrZipCod"
            placeholder="รหัสไปรษณีย์"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="หมายเลขมือถือ"
          label-for="cusPhnNum"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              หมายเลขมือถือ
            </div>
          </template>
          <b-form-input
            id="cusPhnNum"
            v-model="getInputCusPhnNum"
            placeholder="หมายเลขมือถือ"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>

      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="หมายเลขโทรศัพท์ 2"
          label-for="adrTelNum"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              หมายเลขโทรศัพท์ 2
            </div>
          </template>
          <b-form-input
            id="adrTelNum"
            v-model="getInputAdrTelNum"
            placeholder="หมายเลขโทรศัพท์ 2"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="Email"
          label-for="cusEmail"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              Email
            </div>
          </template>
          <b-form-input
            id="cusEmail"
            v-model="getInputCusEmail"
            placeholder="Email"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>

      <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
        <b-form-group
          label="Line ID"
          label-for="lineId"
          label-cols-md="3"
          label-cols-sm="12"
        >
          <template v-slot:label>
            <div style="margin-top:10px;">
              Line ID
            </div>
          </template>
          <b-form-input
            id="lineId"
            v-model="getInputLineId"
            placeholder="Line ID"
            trim
            autocomplete="off"
            disabled
          />

        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            @click="fncTabNext()"
          >
            ถัดไป
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton, BFormGroup, BInputGroup,
} from 'bootstrap-vue'
import {
  ref, computed,
} from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import {
  VDatePicker,
} from 'vuetify/lib'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormGroup,
    BInputGroup,
    VDatePicker,

  },

  props: {
    tabIndex: {
      type: Number,
      required: true,
    },

    branchCode: {
      type: String,
      default: null,
    },

    branchName: {
      type: String,
      default: null,
    },

    inputRegNum1: {
      type: String,
      default: null,
    },

    inputRegNum2: {
      type: String,
      default: null,
    },

    chassisNum: {
      type: String,
      default: null,
    },

    inputGenAppNum: {
      type: String,
      default: null,
    },

    inputConNum: {
      type: String,
      default: null,
    },

    inputCooPerateCode: {
      type: String,
      default: null,
    },

    inputCarLocation: {
      type: String,
      default: null,
    },

    inputPsnRegIdn: {
      type: String,
      default: null,
    },

    salesCode: {
      type: String,
      default: null,
    },

    salesName: {
      type: String,
      default: null,
    },

    cusPsnCode: {
      type: String,
      default: null,
    },

    cusPsnName: {
      type: String,
      default: null,
    },

    inputIdStrDate: {
      type: String,
      default: null,
    },

    inputIdEndDate: {
      type: String,
      default: null,
    },

    inputBthDate: {
      type: String,
      default: null,
    },

    inputDrvLicStr: {
      type: String,
      default: null,
    },

    inputDrvLicEnd: {
      type: String,
      default: null,
    },

    inputCusNamTha: {
      type: String,
      default: null,
    },

    inputCusSurTha: {
      type: String,
      default: null,
    },

    inputDrvLicenseNo: {
      type: String,
      default: null,
    },

    inputAdrDtlln1: {
      type: String,
      default: null,
    },

    inputAdrDisTrict: {
      type: String,
      default: null,
    },

    inputAdrAmPhur: {
      type: String,
      default: null,
    },

    inputAdrProVince: {
      type: String,
      default: null,
    },

    inputAdrZipCod: {
      type: String,
      default: null,
    },

    inputCusPhnNum: {
      type: String,
      default: null,
    },

    inputAdrTelNum: {
      type: String,
      default: null,
    },

    inputCusEmail: {
      type: String,
      default: null,
    },

    inputLineId: {
      type: String,
      default: null,
    },

  },

  setup(props, { emit }) {
    const APP_STORE_MODULE_NAME = 'appContractInquiry'

    // ======================= 2 WAY BINDING PROPS =======================
    const getBranchCode = computed({
      get() {
        return props.branchCode
      },
      set(val) {
        emit('update:branch-code', val)
      },
    })

    const getBranchName = computed({
      get() {
        return props.branchName
      },
      set(val) {
        emit('update:branch-name', val)
      },
    })

    const getInputRegNum1 = computed({
      get() {
        return props.inputRegNum1
      },
      set(val) {
        emit('update:input-reg-num1', val)
      },
    })

    const getInputRegNum2 = computed({
      get() {
        return props.inputRegNum2
      },
      set(val) {
        emit('update:input-reg-num2', val)
      },
    })

    const getChassisNum = computed({
      get() {
        return props.chassisNum
      },
      set(val) {
        emit('update:chassis-num', val)
      },
    })

    const getInputGenAppNum = computed({
      get() {
        return props.inputGenAppNum
      },
      set(val) {
        emit('update:input-gen-app-num', val)
      },
    })

    const getInputConNum = computed({
      get() {
        return props.inputConNum
      },
      set(val) {
        emit('update:input-con-num', val)
      },
    })

    const getInputCooPerateCode = computed({
      get() {
        return props.inputCooPerateCode
      },
      set(val) {
        emit('update:input-coo-perate-code', val)
      },
    })

    const getInputCarLocation = computed({
      get() {
        return props.inputCarLocation
      },
      set(val) {
        emit('update:input-car-location', val)
      },
    })

    const getInputPsnRegIdn = computed({
      get() {
        return props.inputPsnRegIdn
      },
      set(val) {
        emit('update:input-psn-reg-idn', val)
      },
    })

    const getSalesCode = computed({
      get() {
        return props.salesCode
      },
      set(val) {
        emit('update:sales-code', val)
      },
    })

    const getSalesName = computed({
      get() {
        return props.salesName
      },
      set(val) {
        emit('update:sales-name', val)
      },
    })

    const getCusPsnCode = computed({
      get() {
        return props.cusPsnCode
      },
      set(val) {
        emit('update:cus-psn-code', val)
      },
    })

    const getCusPsnName = computed({
      get() {
        return props.cusPsnName
      },
      set(val) {
        emit('update:cus-psn-name', val)
      },
    })

    const getInputIdStrDate = computed({
      get() {
        return props.inputIdStrDate
      },
      set(val) {
        emit('update:input-id-str-date', val)
      },
    })

    const getInputIdEndDate = computed({
      get() {
        return props.inputIdEndDate
      },
      set(val) {
        emit('update:input-id-end-date', val)
      },
    })

    const getInputBthDate = computed({
      get() {
        return props.inputBthDate
      },
      set(val) {
        emit('update:input-bth-date', val)
      },
    })

    const getInputDrvLicStr = computed({
      get() {
        return props.inputDrvLicStr
      },
      set(val) {
        emit('update:input-drv-lic-str', val)
      },
    })

    const getInputDrvLicEnd = computed({
      get() {
        return props.inputDrvLicEnd
      },
      set(val) {
        emit('update:input-drv-lic-end', val)
      },
    })

    const getInputCusNamTha = computed({
      get() {
        return props.inputCusNamTha
      },
      set(val) {
        emit('update:input-cus-nam-tha', val)
      },
    })

    const getInputCusSurTha = computed({
      get() {
        return props.inputCusSurTha
      },
      set(val) {
        emit('update:input-cus-sur-tha', val)
      },
    })

    const getInputDrvLicenseNo = computed({
      get() {
        return props.inputDrvLicenseNo
      },
      set(val) {
        emit('update:input-drv-license-no', val)
      },
    })

    const getInputAdrDtlln1 = computed({
      get() {
        return props.inputAdrDtlln1
      },
      set(val) {
        emit('update:input-adr-dtlln1', val)
      },
    })

    const getInputAdrDisTrict = computed({
      get() {
        return props.inputAdrDisTrict
      },
      set(val) {
        emit('update:input-adr-dis-trict', val)
      },
    })

    const getInputAdrAmPhur = computed({
      get() {
        return props.inputAdrAmPhur
      },
      set(val) {
        emit('update:input-adr-am-phur', val)
      },
    })

    const getInputAdrProVince = computed({
      get() {
        return props.inputAdrProVince
      },
      set(val) {
        emit('update:input-adr-pro-vince', val)
      },
    })

    const getInputAdrZipCod = computed({
      get() {
        return props.inputAdrZipCod
      },
      set(val) {
        emit('update:input-adr-zip-cod', val)
      },
    })

    const getInputCusPhnNum = computed({
      get() {
        return props.inputCusPhnNum
      },
      set(val) {
        emit('update:input-cus-phn-num', val)
      },
    })

    const getInputAdrTelNum = computed({
      get() {
        return props.inputAdrTelNum
      },
      set(val) {
        emit('update:input-adr-tel-num', val)
      },
    })

    const getInputCusEmail = computed({
      get() {
        return props.inputCusEmail
      },
      set(val) {
        emit('update:input-cus-email', val)
      },
    })

    const getInputLineId = computed({
      get() {
        return props.inputLineId
      },
      set(val) {
        emit('update:input-line-id', val)
      },
    })

    // ======================= 2 WAY BINDING PROPS =======================

    // ประกาศตัวแปร Ref
    const refModalDatePickerIdStrDate = ref(null)
    const refModalDatePickerIdEndDate = ref(null)
    const refModalDatePickerBthDate = ref(null)
    const refModalDatePickerDrvLicStr = ref(null)
    const refModalDatePickerDrvLicEnd = ref(null)

    // ประกาศตัวแปร Textbox
    const inputSearchBranch = ref(null)
    const inputSearchChassisNum = ref(null)
    const inputSearchSales = ref(null)
    const inputSearchCusPsn = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const tempSearchBranch = ref(null)
    const tempSearchChassisNum = ref(null)
    const tempSearchSales = ref(null)
    const tempSearchCusPsn = ref(null)

    // ประกาศตัวแปร Active / Inactive
    const isPopupBranchActive = ref(false)
    const isPopupChassisNumActive = ref(false)
    const isPopupSalesActive = ref(null)
    const isPopupCusPsnActive = ref(null)

    /* ฟังก์ชั่นแสดง DatePicker วันที่ออกบัตร ปชช. */
    const fncShowDatepickerIdStrDate = () => {
      refModalDatePickerIdStrDate.value.show()
    }

    /* ฟังก์ชั่นแสดง DatePicker วันที่หมดอายุบัตร ปชช. */
    const fncShowDatepickerIdEndDate = () => {
      refModalDatePickerIdEndDate.value.show()
    }

    /* ฟังก์ชั่นแสดง DatePicker วันเกิด */
    const fncShowDatepickerBthDate = () => {
      refModalDatePickerBthDate.value.show()
    }

    /* ฟังก์ชั่นแสดง DatePicker วันที่ออกใบขับขี่ */
    const fncShowDatepickerDrvLicStr = () => {
      refModalDatePickerDrvLicStr.value.show()
    }

    /* ฟังก์ชั่นแสดง DatePicker วันที่หมดอายุใบขับขี่ */
    const fncShowDatepickerDrvLicEnd = () => {
      refModalDatePickerDrvLicEnd.value.show()
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกพอร์ตจาก Popup */
    const fncOpenPopupBranch = search => {
      if (search) tempSearchBranch.value = search
      else tempSearchBranch.value = null

      isPopupBranchActive.value = true
    }

    const fncSearchBranch = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CPNBRNCOD',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              getBranchCode.value = getSearchResult[0].tabkeytwo
              getBranchName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupBranch(val)
            }
          })
      }
    }

    const fncSelectBranch = data => {
      getBranchCode.value = data.tabkeytwo
      getBranchName.value = data.tabdsctha
    }

    /* ฟังก์ชั่นยกเลิกพอร์ต */
    const fncCancelBranch = () => {
      getBranchCode.value = null
      getBranchName.value = null
      inputSearchBranch.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกเลขทะเบียนจาก Popup */
    const fncSelectChassisNum = data => {
      getChassisNum.value = data.chassis_number
      getInputRegNum1.value = data.regnum1
      getInputRegNum2.value = data.regnum2
      console.log(data)
    }

    /* ฟังก์ชั่นเปิด Popup เลขทะเบียน */
    const fncOpenPopupChassisNum = search => {
      if (search) tempSearchChassisNum.value = search
      else tempSearchChassisNum.value = null

      isPopupChassisNumActive.value = true
      console.log()
    }

    /* ฟังก์ชั่นยกเลิกเลขทะเบียน */
    const fncCancelChassisNum = () => {
      getChassisNum.value = null
      getInputRegNum1.value = null
      getInputRegNum2.value = null
    }

    /* ฟังก์ชั่นเปิด Popup เซลล์ */
    const fncOpenPopupSales = search => {
      if (search) tempSearchSales.value = search
      else tempSearchSales.value = null

      isPopupSalesActive.value = true
    }

    /* ฟังก์ชั่นค้นหาเซลล์ */
    const fncSearchSales = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: 'GENERAL',
          function: 'GET',
          username: null,
          access_time: null,
          cpncod_in: '0001',
          cpnbrncod_in: '0001',
          accbustyp_in: '4020',
          accdeawth_in: 'MKT',
          acccod_in: null,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_GET_ACCOUNT_MASTER`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.acccod.toLowerCase().indexOf(val) > -1 || item.account_name.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              getSalesCode.value = getSearchResult[0].acccod
              getSalesName.value = getSearchResult[0].account_name
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupSales(val)
            }
          })
      }
    }
    /* ฟังก์ชั่นเมื่อมีการกดเลือกเซลล์นจาก Popup */
    const fncSelectSales = data => {
      getSalesCode.value = data.acccod
      getSalesName.value = data.account_name
    }

    /* ฟังก์ชั่นยกเลิกเซลล์ */
    const fncCancelSales = () => {
      getSalesCode.value = null
      getSalesName.value = null
      inputSearchSales.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกคำนำหน้าจาก Popup */
    const fncOpenPopupCusPsn = search => {
      if (search) tempSearchCusPsn.value = search
      else tempSearchCusPsn.value = null

      isPopupCusPsnActive.value = true
    }

    const fncSelectCusPsn = data => {
      getCusPsnCode.value = data.tabdsctha
      getCusPsnName.value = data.tabkeytwo
    }

    const fncSearchCusPsn = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CUSTTLPSN',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              getCusPsnCode.value = getSearchResult[0].tabkeytwo
              getCusPsnName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupCusPsn(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกคำนำหน้า */
    const fncCancelCusPsn = () => {
      getCusPsnCode.value = null
      getCusPsnName.value = null
      inputSearchCusPsn.value = null
    }

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    // ตัวแปรกด Tab ถัดไป
    const fncTabNext = () => {
      emit('update:tab-index', Number(props.tabIndex) + 1)
    }

    return {
      // 2 WAY BINDING PROPS
      getBranchCode,
      getBranchName,
      getInputRegNum1,
      getInputRegNum2,
      getChassisNum,
      getInputGenAppNum,
      getInputConNum,
      getInputCooPerateCode,
      getInputCarLocation,
      getInputPsnRegIdn,
      getSalesCode,
      getSalesName,
      getCusPsnCode,
      getCusPsnName,
      getInputCusNamTha,
      getInputCusSurTha,
      getInputDrvLicenseNo,
      getInputAdrDtlln1,
      getInputAdrDisTrict,
      getInputAdrAmPhur,
      getInputAdrProVince,
      getInputAdrZipCod,
      getInputCusPhnNum,
      getInputAdrTelNum,
      getInputCusEmail,
      getInputLineId,

      // วันที่ต่างๆ
      getInputIdStrDate,
      getInputIdEndDate,
      getInputBthDate,
      getInputDrvLicStr,
      getInputDrvLicEnd,

      // ประกาศตัวแปร Textbox
      inputSearchBranch,
      inputSearchChassisNum,
      inputSearchSales,
      inputSearchCusPsn,

      // ประกาศตัวแปรสำหรับเก็บค่า
      tempSearchBranch,
      tempSearchChassisNum,
      tempSearchSales,
      tempSearchCusPsn,

      // ประกาศตัวแปร Active / Inactive
      isPopupBranchActive,
      isPopupChassisNumActive,
      isPopupSalesActive,
      isPopupCusPsnActive,

      // ตัวแปรทั้งหมด
      fncSearchBranch,
      fncOpenPopupBranch,
      fncSelectBranch,
      fncCancelBranch,
      fncSelectChassisNum,
      fncOpenPopupChassisNum,
      fncCancelChassisNum,
      fncSearchSales,
      fncOpenPopupSales,
      fncSelectSales,
      fncCancelSales,
      fncSearchCusPsn,
      fncOpenPopupCusPsn,
      fncSelectCusPsn,
      fncCancelCusPsn,
      fncTabNext,

      // fnc การทำงานวันที่ต่างๆ
      fncShowDatepickerIdStrDate,
      fncShowDatepickerIdEndDate,
      fncShowDatepickerBthDate,
      fncShowDatepickerDrvLicStr,
      fncShowDatepickerDrvLicEnd,

      // ประกาศตัวแปร Ref
      refModalDatePickerIdStrDate,
      refModalDatePickerIdEndDate,
      refModalDatePickerBthDate,
      refModalDatePickerDrvLicStr,
      refModalDatePickerDrvLicEnd,
      // ส่วนการทำงานแสดงผล UI
      resolveFormatDate,
    }
  },
}
</script>

<style>
.v-date-picker-title {
    color: black;
}

.v-picker__title.primary {
  margin-top: -80px;
}

.v-date-picker-table.v-date-picker-table--date.theme--light {
  margin-bottom: -30px;
}

.v-picker.v-card.v-picker--date.theme--light {
  margin-left: 0px;
}

button.v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-date-picker-table__current.v-btn--active.v-btn--text.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-btn--active.theme--light.accent {
  color: blue;
}

.v-picker__title.primary {
  display: none;
}

.modalDatePicker .modal-body {
  padding: 0px;
}

.modalDatePicker .modal-content {
  width: 280px;
}

.v-picker__body.theme--light {
  width: 280px !important;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}
</style>
